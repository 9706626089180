<template>
  <div>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4>Time Amounts Index</h4>
      <!-- Create Button Organization -->
      <router-link :to="{ name: 'TimeAmountNew' }" tag="button" type="button" class="btn btn-success">Create Time Amount</router-link>
    </div>
    <div v-if="timeAmounts">

      <!-- Organization Index -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="rainbow-background"></div>
            <b-checkbox id="users-archived" v-model="filters.is_archived" v-on:input="getTimeAmounts(currentPage, perPage)" switch>
              View Archived Time Amounts
            </b-checkbox>
            <!-- Table Headers and Search Bar -->
            <ransack-filtering :headerWidth='38' :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters"></ransack-filtering>

            <div class="table-responsive">
              <table class="card-table table">
                <tbody>
                <tr v-for="timeAmount in timeAmounts" :key="timeAmount.id" :id="timeAmount.id">
                  <td class="text-left">
                    {{ timeAmount.name }}
                  </td>
                  <td class="text-left">
                    {{ timeAmount.amount }}
                  </td>
                  <td class="w-20">
                    <div class="btn-group float-right">
                      <router-link :to="{ name: 'TimeAmountEdit', params: { id: timeAmount.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                      <router-link :to="{ name: 'TimeAmountShow', params: { id: timeAmount.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange"></pagination>

          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { TimeAmountService } from '@/common/services/time_amount.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'TimeAmountIndex',
  components: {
    Pagination,
    RansackFiltering,
    LoadingOverlay,
  },
  data() {
    return {
      timeAmounts: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Name', 'Amount'],
      searchParam: 'name_cont',
      filters: FilterService.defaultData(),
    };
  },
  created() {
    this.getTimeAmounts(this.currentPage, this.perPage);
  },
  methods: {
    getTimeAmounts(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TimeAmountService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.timeAmounts = response.data;
      });
    },
    onChange(page, perPage) {
      this.getTimeAmounts(page, perPage);
    },
    setFilters(filters) {
      this.filters = filters;
      this.getTimeAmounts(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/time_amounts";
</style>
